import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Header, {
  LandingHeaderLinks,
  LandingHeaderButton,
} from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './landing.scss';

import PhoneImage from './images/phone.png';
import TopImage from './images/top.webp';
import LeftImage from './images/bottom.webp';
import BottomImage from './images/left.webp';
import amazonImage from './images/amazon.png';
import tinderImage from './images/tinder.png';
import uberImage from './images/eat.png';
import psnImage from './images/psn.png';

const Landing = () => {
  const textSwitch = () => {
    const animatedWordList = Array.from(
      document.querySelectorAll('span.animatedword')
    );
    const animatedWordListCount = animatedWordList.length;
    let activeAnimatedWordIndex = 0;

    setInterval(() => {
      const activeAnimatedWord = document.querySelector(
        'span.animatedword.animated-in'
      );

      if (
        animatedWordList.indexOf(activeAnimatedWord) ===
        animatedWordListCount - 1
      )
        activeAnimatedWordIndex = 0;
      else activeAnimatedWordIndex += 1;

      activeAnimatedWord.classList.remove('animated-in');
      document
        .querySelectorAll('span.animatedword')
        [activeAnimatedWordIndex].classList.add('animated-in');
    }, 2000);
  };

  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    const imageHook = gsap.utils.selector(el);
    tl.current = gsap
      .timeline()
      .from(imageHook('.c-media__image--1'), {
        y: 350,
        scale: 0,
        opacity: 0,
        duration: 0.6,
      })
      .from(imageHook('.c-media__image--2'), {
        x: -100,
        opacity: 0,
        duration: 0.5,
      })
      .from(imageHook('.c-media__image--4'), {
        x: 100,
        opacity: 0,
        duration: 0.45,
      })
      .from(imageHook('.c-media__image--3'), {
        x: -100,
        opacity: 0,
        duration: 0.55,
      });

    textSwitch();
  }, []);

  return (
    <main className="l-wrapper-full">
      <div className="c-glow">
        <div className="c__glow-wrap">
          <div className="c__glow c__glow--one" />
          <div className="c__glow c__glow--two" />
          <div className="c__glow c__glow--three" />
        </div>
      </div>
      <div className="c-home" ref={el}>
        <Header navtButton={<LandingHeaderButton />}>
          <LandingHeaderLinks />
        </Header>
        <div className="c-manifest">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <div className="c-manifest__text">
                  <h2>
                    The fastest way to spend{' '}
                    <span>
                      crypto on
                      <i className="word-wrap">
                        <span className="animatedword animated-in">
                          {' '}
                          <img src={amazonImage} alt="amazon" /> amazon
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#04c167' }}
                        >
                          {' '}
                          <img src={uberImage} alt="uber" /> uberEats
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#ff615d' }}
                        >
                          {' '}
                          <img src={tinderImage} alt="tinder" /> tinder
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#007ec9' }}
                        >
                          <img src={psnImage} alt="psn network" /> Playstation
                        </span>
                      </i>
                    </span>
                  </h2>
                  <p>
                    Create a USD virtual card, fund it from your USDC wallet on
                    solana and spend it on PayPal, Amazon, Netflix & more.{' '}
                  </p>
                  {/* <a href="" className="btn">Join Waitlist</a> */}
                  {/* <div className="c-manifest__button">
                  <a href="/" type="button">
                    <span>Early Access</span>
                  </a>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-media">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="c-media__images-wrap">
                <img
                  src={PhoneImage}
                  alt=""
                  className="c-media__image c-media__image--1"
                />
                <img
                  src={TopImage}
                  alt=""
                  className="c-media__image c-media__image--2"
                />
                <img
                  src={LeftImage}
                  alt=""
                  className="c-media__image c-media__image--3"
                />
                <img
                  src={BottomImage}
                  alt=""
                  className="c-media__image c-media__image--4"
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </main>
  );
};

export default Landing;
