import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../images/logo-alt.svg';
import './header.scss';

const LandingHeaderLinks = () => (
  <ul>
    <li className="c-nav__link">
      <Link to="/">Home</Link>
    </li>
    <li className="c-nav__link">
      <Link to="/privacy">Privacy</Link>
    </li>
    <li className="c-nav__link">
      <Link to="/terms">Terms & Condition</Link>
    </li>
  </ul>
);

const LandingHeaderButton = () => (
  <a href="/" type="button">
    <span>Early Access</span>
  </a>
);

export default function Header({ navtButton, children }) {
  return (
    <header className="c-header">
      <nav className="c-nav">
        <div className="c-nav__logo">
          <a href="/">
            <img src={Logo} alt="kurobi Pay" />
          </a>
        </div>

        <menu className="c-nav__menu">{children}</menu>

        <div className="c-nav__buttons">{navtButton}</div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  navtButton: PropTypes.node.isRequired,
};

export { LandingHeaderLinks, LandingHeaderButton };
