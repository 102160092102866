import React from 'react';
import Logo from '../images/logo-alt.svg';
import './footer.scss';

export default function Footer() {
  return (
    <footer className="c-footer">
      <div className="l-wrapper">
        <div className="l-row">
          <div className="l-col-12">
            <div className="c-footer__links l-flex-column">
              <img src={Logo} alt="" />
              Kuropay Ltd. © 2022. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
