import React from 'react';
import { Link } from 'react-router-dom';
import Header, {
  LandingHeaderLinks,
  LandingHeaderButton,
} from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import '../legal.scss';

const Privacy = () => (
  <main className="l-wrapper-full">
    <Header navtButton={<LandingHeaderButton />}>
      <LandingHeaderLinks />
    </Header>
    <section className="c-legal c-legal--privacy">
      <article className="c-legal__hero">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-9 l-flex-column">
              <h5>Privacy Policy</h5>
              <h3>The following document outlines kuropay's privacy policy</h3>
              <h6>Effective date: August 29th, 2022</h6>
            </div>
          </div>
        </div>
      </article>

      <article className="c-legal__content">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-7">
              <div
                className="c-legal__manifest"
                style={{ margin: '4.8rem 0 2.0rem 0', opacity: '.75' }}
              >
                <p>
                  This Privacy Statement (the "Privacy Statement") is provided
                  by: kuropay ("The Company")
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Introduction</h2>
                <p>
                  This policy describes what information we collect when you use
                  kuropay’s sites, services, mobile applications, products, and
                  content (“Services”). It also provides information about how
                  we store, transfer, use, and delete that information, and what
                  choices you have with respect to the information.
                </p>
                <p>
                  This policy applies to kuropay’s online video meeting tool,
                  including the website and mobile applications, and other
                  kuropay websites (collectively “the Websites”).
                </p>
                <p>
                  This policy applies where we are acting as a Data Controller
                  with respect to the personal data of Hosts and Users of our
                  Services; in other words, where we determine the purposes and
                  means of the processing of that personal data. For content and
                  data that you upload to or make available through the Service
                  (“User Content”), you are responsible for ensuring this
                  content is in accordance with our Terms of Service, and that
                  the content is not violating other Hosts and Users’ privacy
                </p>
                <i>How we collect, process and store information</i>
                <p>
                  We in kuropay are committed to safeguarding the privacy of our
                  Hosts and Users. Our business model is to withhold a small fee
                  from video calls and other services offered through the
                  kuropay Platform, and does not rely on widespread collection
                  of general user data. We will only collect and process
                  information that we need to deliver the service to you, and to
                  continue to maintain and develop the service.
                </p>
                <p>
                  kuropay may collect, store and process various kinds of data,
                  with different legal grounds, as listed below. For the
                  categories of data that require your consent, we will actively
                  ask you for consent before collecting any data. You can give
                  and revoke your consents at any time in your Settings page in{' '}
                  <a rel="noreferrer" href="http://" target="_blank">
                    {' '}
                    https://kuropay.io.
                  </a>
                </p>
                The following is a list of data we collect, process or store:
                <p>
                  Service Information. When you interact with the Service, we
                  automatically store a recording of a video call on our server
                  with the purpose of using it exclusively for mitigating user
                  disputes of the Service, as explained in our FAQs, and
                  performing the contract we entered into with you, at your
                  request (our Terms of Service). If dispute was not raised by
                  the User within the time, defined in our FAQs, such recordings
                  are automatically deleted.
                </p>
                <p>
                  Host and User account information. To use kuropay Service,
                  Hosts and Hosts and Users might have to provide a valid email
                  address, date of birth, full name, and country of residence.
                  The Host can also choose to enter a display name, add a
                  profile picture and/or video, and short bio that will be used
                  to represent them on their public profile page, and in
                  conversations.
                </p>
                <p>
                  Transaction information. Hosts that register on kuropay, as
                  well as Hosts and Users of their Services might have to
                  provide credit card information, billing email, banking
                  information, location at the time of transaction and/or a
                  billing address in order to complete a booking transaction.
                  The transaction data may be processed for the purpose of
                  supplying the purchased services and keeping proper records of
                  those transactions. This data may be used for the purpose of
                  delivering the Services to you. Processing this information is
                  required for fulfilling the contract we entered into with you,
                  at your request (our Terms of Service). Additionally, this
                  information needs to be retained in order to comply with
                  accounting and tax regulation.
                </p>
                <p>
                  Usage information. When you interact with the Services, we
                  collect and process metadata to provide additional context
                  about the way the Service is being used. The usage data may
                  include your IP address, geographical location, browser type
                  and version, operating system, referral source, length of
                  visit, page views and website navigation paths, as well as
                  information about the timing, frequency and pattern of your
                  service use. The source of the usage data is our server
                  software and our analytics tracking system.
                </p>
                <p>
                  Product Analytics data. kuropay logs activities by you and
                  other Hosts and Users when the Hosts and Users interact with
                  our websites or apps, when a page or a room is visited or
                  where there is a conversation. We will never collect or record
                  the content in conversations.
                </p>
                <p>
                  Technical log data. Like most digital services, our servers
                  automatically collect information when Websites or Services
                  are accessed or used and record it in log files. This log data
                  may include the Internet Protocol (IP) address, the address of
                  the web page visited within the Services, browser type and
                  settings, the date and time the Services were used,
                  information about browser configuration and plugins, language
                  preferences and cookie data.
                </p>
                <p>
                  Device information. kuropay may collect and process
                  information about devices used to access the Services,
                  including type of device, what operating system is used,
                  device settings, application IDs, unique device identifiers
                  and crash data. Whether we collect and process some or all of
                  this information depends on the type of device used and its
                  settings.
                </p>
                <p>
                  The legal basis for this processing is our legitimate
                  interests, namely using this data for the purpose of ensuring
                  the proper administration of our website and business,
                  analyzing the use of the website and services, monitoring and
                  improving our website and services, improving the user
                  experience, preventing abuse, and assisting Hosts and Users
                  with support inquiries. For information about cookies and how
                  to opt out of cookies, see our Cookie Policy.
                </p>
                <p>
                  Customer Support Information. We may process information that
                  you send to us, should you choose to submit a ticket to our
                  support email. If you contact us, we may use your Account,
                  Transaction or Usage Information to respond. Processing this
                  information it is required for performing the contract we
                  entered into with you, at your request (our Terms of Service),
                  as well as our legitimate interest of handling your requests.
                </p>
                <p>
                  Service and transactional notifications. Sometimes we’ll send
                  you emails about your account, service changes or new
                  policies. You can’t opt out of this type of “service or
                  transactional” emails (unless you delete your account) as they
                  are necessary information for the Services. The legal grounds
                  for processing this information is that it is required for
                  performing our commitment about communicating changes in plans
                  and pricing to you in the contract we entered into with you,
                  at your request (our Terms of Service).
                </p>
                <p>
                  Correspondence information. We may process information that
                  you choose to share with us if you participate in a focus
                  group, contest, activity or event, apply for a job, interact
                  with our social media accounts or otherwise communicate with
                  kuropay The correspondence data may be processed for the
                  purposes of communicating with you and record-keeping.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Security</h2>
                <p>
                  In the FREE version of the Service, Hosts and Users can use
                  “Small meeting” mode (up to 4 participants). In “Small
                  meeting” mode, communication between participants are
                  primarily sent through peer-to-peer connections, where audio
                  and video streams are sent directly between participants and
                  do not pass through any of our servers. Video and audio
                  transmitted in the Service is then sent directly between the
                  participants in a room and is encrypted (DTLS-SRTP) with
                  client-generated encryption keys. In cases where a user is
                  behind a strict firewall or NAT, video and audio need to be
                  relayed via a TURN server, but end-to-end encryption is still
                  maintained.
                </p>
                <p>
                  If you have upgraded a room to PRO, you can choose to use
                  “Large meeting” mode (up to 12 participants). Calls using
                  “Large meeting” mode will use a dedicated server
                  infrastructure to allow more people in conversation, and
                  better stability. Your stream will be sent through video
                  router servers which transmits it to the other participants in
                  the call, and also transmits their streams to you. Streams
                  will always be encrypted (DTLS-SRTP) in transit, but will be
                  decrypted and re-encrypted when passing through the video
                  routers. We operate an infrastructure of video routers
                  distributed across the world, and you will be automatically
                  routed to the closest one. The video router servers and all of
                  our infrastructure adhere to strict security measures,
                  preventing any eavesdropping or interruption of the
                  video/audio streams.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Providing your personal data to others</h2>
                <p>
                  We may share information about you with third parties in some
                  circumstances, including: (1) with your consent; (2) to a
                  service provider or partner who meets our data protection
                  standards; (3) with academic or non-profit researchers, with
                  aggregation, anonymization; (4) when we have a good faith
                  belief it is required by law, such as pursuant to a subpoena
                  or other legal process; (5) to protect the vital interest of
                  others, when we have reason to believe that doing so will
                  prevent harm to someone or illegal activities. Our categories
                  of service providers and partners are:
                </p>
                <ul>
                  <li>Hosting/infrastructure/storage providers</li>
                  <li>Payment processors</li>
                  <li>Analysis tools providers</li>
                  <li>Marketing and email providers</li>
                  <li>Recruiting tools providers</li>
                  <li>Internal communication tools providers</li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Business Transfers</h2>
                <p>
                  We may disclose your personal data to any member of our group
                  of companies (this means our subsidiaries, our ultimate
                  holding company and all its subsidiaries) insofar as
                  reasonably necessary for the purposes, and on the legal bases,
                  set out in this policy.
                </p>
                <p>
                  In the case where we are involved in a merger, acquisition,
                  bankruptcy, reorganization or sale of assets such that your
                  information would be transferred or become subject to a
                  different privacy policy, we will notify you in advance and
                  give you the option to delete your data before the transfer.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>International transfers of your personal data</h2>
                <p>
                  In some circumstances your personal data may be transferred to
                  countries outside the European Economic Area (EEA). You
                  acknowledge that personal data that you submit for publication
                  through our website or services may be available, via the
                  internet, around the world. We cannot prevent the use (or
                  misuse) of such personal data by others. For information about
                  what types of content you are, see this{' '}
                  <Link to="/terms">Terms of Service.</Link>
                </p>
                <p>
                  We and our other group companies have offices and facilities
                  in Norway, Sweden, United States. The hosting facilities for
                  Account information stored by kuropay are situated in Ireland.
                  The hosting facilities for Usage information are situated in
                  Ireland and the United States. Transfers to the United States
                  will be protected by appropriate safeguards, namely the use of
                  standard data protection clauses adopted or approved by the
                  European Commission, a copy of which can be obtained from{' '}
                  <a
                    rel="noreferrer"
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contr"
                    target="_blank"
                  >
                    acts-transfer-personal-data-third-countries_en.
                  </a>
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Retaining and deleting personal data</h2>
                <p>
                  Personal data that we process for any purpose or purposes
                  shall not be kept for longer than is necessary for that
                  purpose or those purposes. We will retain your personal data
                  as follows:
                </p>
                <p>
                  Transaction information will be retained for a minimum period
                  of 5 years following the date of the transaction, and for a
                  maximum period of 10 years following the date of the
                  transaction.
                </p>
                <p>
                  In some cases it is not possible for us to specify in advance
                  the periods for which your personal data will be retained. In
                  such cases, we will determine the period of retention based on
                  the following criteria:
                </p>
                <p>
                  Account information will be retained until you decide to
                  delete your account on kuropay. Information about you used for
                  Product & Marketing communication will be retained as long as
                  you have given us consent to use this information.
                </p>
                <p>
                  The period of retention of usage information will be
                  determined based on the need for historical data to determine
                  statistical validity and relevance for product decisions and
                  technical monitoring
                </p>
                <p>
                  Regardless of the provisions above, we may retain your
                  personal data where such retention is necessary for compliance
                  with a legal obligation to which we are subject, or in order
                  to protect your vital interests or the vital interests of
                  another natural person.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Changes to this policy</h2>
                <p>
                  We can change these Terms at any time. If a change is
                  material, we’ll let you know before it takes effect. By using
                  kuropay on or after that effective date, you agree to the new
                  Terms. If you don’t agree to them, you should contact kuropay
                  at admin@kuropay.io with the request to delete your account
                  before they take effect, otherwise your use of the Service and
                  Content will be subject to the new Terms.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Managing and deleting your personal information</h2>
                <p>
                  If you have a kuropay account, you can access, and modify your
                  personal information, in Settings. To delete your account, you
                  should contact kuropay at{' '}
                  <span className="kuropay-mail">admin@kuropay.io</span> . If
                  you request to delete your account, your information and
                  content will be unrecoverable after that time. We may withhold
                  personal information that you request to the extent permitted
                  by law.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Your rights</h2>
                <p>
                  As an individual you are granted rights according to the
                  applicable data protection law:
                </p>
                <ul>
                  <li>The right to access to your personal data</li>
                  <li>The right to rectification of your personal data</li>
                  <li>
                    The right to object to and restriction of our processing of
                    your personal data
                  </li>
                  <li>The right to data portability.</li>
                </ul>
                <p>
                  To exercise your rights or if you otherwise have any questions
                  regarding our processing of your personal data, we encourage
                  you to contact us as described below. However, we also notify
                  you that you may raise a complaint to a data protection
                  authority. For any questions about this privacy policy, please
                  contact <span>admin@kuropay.io</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <Footer />
  </main>
);

export default Privacy;
