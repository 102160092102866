import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  withRouter,
} from 'react-router-dom';
import Landing from './pages/landing/landing';
import Terms from './pages/legal/terms/terms';
import Privacy from './pages/legal/privacy/privacy';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Route path="/" component={Landing} exact />
      <Route path="/privacy" component={Privacy} />
      <Route path="/terms" component={Terms} />
    </ScrollToTop>
  </Router>
);

export default Routes;
